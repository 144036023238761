import React, {createContext, useContext, useState} from 'react';

export const AdminContext = createContext({});

const AdminProvider = ({children}) => {
  const [admin, setAdmin] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [currentClient, setCurrentClient] = useState({});

  var showClientPage = (event) => {
    event.preventDefault();
    document.addEventListener('click', closeClientPage);
  }

  var closeClientPage = (event) => {
    event.preventDefault();
    if (event.target.id === 'clientInfoModal' || event.target.id === 'closeButton') {
      setCurrentClient({});
      document.removeEventListener('click', closeClientPage);
    } else {
      return;
    }
  }

  return (
    <AdminContext.Provider
      value={{
        admin,
        setAdmin,
        clientList,
        setClientList,
        currentClient,
        setCurrentClient,
        showClientPage,
        closeClientPage
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
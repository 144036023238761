import React from 'react';

var Testimonials = () => {
  return (
    <div id='testimonials'>
      <h1>Testimonials</h1>
      <div id='testimonialsBody'>
        <h2>Name</h2>
        <h3>Testimonial</h3>
      </div>
    </div>
  )
}

export default Testimonials;
import React, {createContext, useContext, useState} from 'react';

export const PageContext = createContext({});

const PageProvider = ({children}) => {
  const [page, setPage] = useState('in progress');
  const [newAccount, setNewAccount] = useState(false);
  const [accountMenu, setAccountMenu] = useState(false);

  var showAccountMenu = (event) => {
    event.preventDefault();
    if (accountMenu === false) {
      setAccountMenu(true);
      document.addEventListener('click', closeAccountMenu);
    } else {
      closeAccountMenu();
    }
  }

  var closeAccountMenu = () => {
    setAccountMenu(false)
    document.removeEventListener('click', closeAccountMenu);
  }

  return (
    <PageContext.Provider
      value={{
        page,
        setPage,
        newAccount,
        setNewAccount,
        accountMenu,
        setAccountMenu,
        showAccountMenu,
        closeAccountMenu
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageProvider;
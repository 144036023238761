import React from 'react';
import APIProvider from './APIContext';
import UserProvider from './UserContext';
import AdminProvider from './AdminContext';
import PageProvider from './PageContext';

export const RootProvider = ({children}) => (
  <PageProvider>
    <AdminProvider>
      <UserProvider>
        <APIProvider>
          {children}
        </APIProvider>
      </UserProvider>
    </AdminProvider>
  </PageProvider>
);
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import {RootProvider} from './context/RootContext';

ReactDOM.render(
  <RootProvider>
    <App />
  </RootProvider>,
  document.getElementById('app')
);
import React, {useContext, useState} from 'react';

import AdminHome from './admin/AdminIndex';
import ClientHome from './client/ClientIndex';

import {APIContext} from '../../context/APIContext';
import {UserContext} from '../../context/UserContext';
import {AdminContext} from '../../context/AdminContext';
import {PageContext} from '../../context/PageContext';

var Login = () => {

  const {userLogin} = useContext(APIContext);
  const {setNewAccount} = useContext(PageContext);


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <div id='login'>
      <form id='loginContent' onSubmit={(e) => {e.preventDefault(); userLogin(email, password)}}>
        <h2>Log In</h2>
        <p onClick={() => setNewAccount(true)}>New user? Sign up here</p><br/>
        <label>Email: <br/>
          <input type='text' id='loginEmail' onChange={(e) => setEmail(e.target.value)}/><br/>
        </label><br/>
        <label>Password:<br/>
          <input type='password' id='loginPassword' onChange={(e) => setPassword(e.target.value)}/><br/>
        </label><br/>
        <input id='accountSubmit' type='submit' value='Submit'/>
      </form>
    </div>
  )
}

export default Login;
import React, {useContext} from 'react';
import $ from 'jquery';
import moment from 'moment';

const axios = require('axios');

import {PageContext} from '../context/PageContext';

import Header from './header/HeaderIndex.jsx';
import Main from './main/MainIndex.jsx';
import Account from './account/AccountIndex.jsx';

var App = () => {

  const {page, setPage} = useContext(PageContext);

  var renderPage = (page) => {
    if (page === 'in progress') {
      return (
      <div>
        <h1>UNDER CONSTRUCTION</h1>
      </div>
      );
    } else if (page === 'main') {
      return (<Main/>);
    } else if (page === 'account') {
      return (<Account/>);
    }
  }


  return (
    <div id='top'>
      <Header />
      <div>
        {renderPage(page)}
      </div>
    </div>
  )

}

export default App;
import React, {useState, useContext} from 'react';
import {Link as ScrollLink, animateScroll as scroll} from 'react-scroll';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

import {UserContext} from '../../context/UserContext';
// import {AdminContext} from '../context/AdminContext';
import {PageContext} from '../../context/PageContext';

var MainHeader = () => {

  const {loggedIn, setLoggedIn, setUser} = useContext(UserContext);
  const {page, setPage, newAccount, setNewAccount, accountMenu, setAccountMenu, showAccountMenu, closeAccountMenu} = useContext(PageContext);

  return (
    <div id='nav'>
      <h1>Personal Training</h1>
      <div id='mainNav'>
        <ScrollLink activeClass='active' to='announcements' horizontal={false} spy={true} smooth={true}><button>News</button></ScrollLink>
        <ScrollLink activeClass='active' to='about' horizontal={false} spy={true} smooth={true}><button>About</button></ScrollLink>
        <ScrollLink activeClass='active' to='testimonials' horizontal={false} spy={true} smooth={true}><button>Reviews</button></ScrollLink>
        <ScrollLink activeClass='active' to='contact' horizontal={false} spy={true} smooth={true}><button>Contact</button></ScrollLink>
        {loggedIn
          ? <div>
              <button onClick={(e) => {showAccountMenu(e)}}>Account</button>
              {accountMenu
                ? <div id='accountDropdown'>
                    <button onClick={() => {setPage('account')}}>My Account</button>
                    <button onClick={() => {setLoggedIn(false); setUser({});}}>Log Off</button>
                  </div>
                : null
              }
            </div>
          : <button onClick={() => {setPage('account')}}>Log In / Sign Up</button>
        }
      </div>
    </div>
  )
}

export default MainHeader;
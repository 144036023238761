import React from 'react';

var About = () => {

  return (
    <div id='about'>
      <h1>About Me</h1>
      <div id='aboutBody'>
        <h2>Introduction</h2>
          <div id='introduction'>
            <div>Image</div>
            <div>
              <p>Based out of Irvine, CA, Daniel has been working with clients to help them reach their fitness goals for over 3 years. Fitness has always been a large part of his life, ever since he started Taekwondo as a child, and his goal is to spread his love for fitness to as many people as possible.</p>
              <p>For Daniel, fitness is about more than running faster, lifting heavier weights, losing weight, etc. Fitness is a lifestyle, and it is Daniel's goal to empower his clients with knowledge so they will know what to do as their goals and bodies change.</p>
            </div>
          </div>
        <h2>Certifications</h2>
        <div id='certifications'>
          <div>
            <h4>NASM CPT</h4>
          </div>
          <div>
            <h4>Kettlebells</h4>
          </div>
          <div>
            <h4>FMS L2</h4>
          </div>
        </div>
        <div id='instagram'>
          Link to IG or Carousel of IG feed
        </div>
      </div>
    </div>
  )
}

export default About;
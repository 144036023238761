import React, {useContext} from 'react';

import ClientList from './ClientList';
import ClientInfo from './ClientInfo';

import {UserContext} from '../../../context/UserContext';
import {AdminContext} from '../../../context/AdminContext';

var AdminHome = () => {

  const {user} = useContext(UserContext);
  const {clientList, currentClient, showClientPage, closeClientPage} = useContext(AdminContext);

  return (
    <div id='adminHome'>
      <h1>Welcome, {user.firstName} {user.lastName}</h1>
      <div>
        {currentClient.firstName
          ? <div id='clientInfoModal'>
              <ClientInfo />
            </div>
          : null
        }
      </div>
      <div>
        {clientList.length > 0
          ? <ClientList />
          : <div>No currently active clients</div>
        }
      </div>
    </div>
  )
}

export default AdminHome;
import React, {useContext} from 'react';

import {UserContext} from '../../context/UserContext';

import Access from './Access.jsx';
import Home from './Home.jsx';

var Account = () => {

  const {loggedIn} = useContext(UserContext);

  return (
    <div id='account'>
      {loggedIn
        ? <Home />
        : <Access />
      }
    </div>
  )
}

export default Account;
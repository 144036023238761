import React from 'react';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Announcements from './Announcements.jsx';
import About from './About.jsx';
import Contact from './Contact.jsx';
import Shop from './Shop.jsx';
import Testimonials from './Testimonials.jsx';

var Main = () => {
  return (
    <div>
      <div id='mainBody'>
        <Announcements />
        <About />
        <Testimonials />
        <Contact />
      </div>
    </div>
  )
}

export default Main;
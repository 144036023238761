import React, {useContext, useState} from 'react';
import $ from 'jquery';

import {APIContext} from '../../context/APIContext';
import {UserContext} from '../../context/UserContext';
import {PageContext} from '../../context/PageContext';

import Login from './Login';
import Signup from './Signup';

var Access = () => {

  const {newAccount} = useContext(PageContext);

  return (
    <div id='accountAccess'>
      {newAccount
        ? <Signup />
        : <Login />
      }
    </div>
  )
}

export default Access;
import React, {useState, useContext} from 'react';
import {Link as ScrollLink, animateScroll as scroll} from 'react-scroll';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

import {UserContext} from '../../context/UserContext';
// import {AdminContext} from '../context/AdminContext';
import {PageContext} from '../../context/PageContext';

var AccountHeader = () => {

  const {loggedIn, setLoggedIn, setUser} = useContext(UserContext);
  const {page, setPage, newAccount, setNewAccount, accountMenu, setAccountMenu, showAccountMenu, closeAccountMenu} = useContext(PageContext);

  return (
    <div id='nav'>
      <h1>Personal Training</h1>
      <div id='accountNav'>
        <div></div> {/* Just to add space */}
        {loggedIn
          ? <div>
              <button onClick={() => {setPage('main')}}>Home</button>
            </div>
          : <div>
              <button onClick={() => {setPage('landing')}}>Landing</button>
            </div>
          }
        {loggedIn
          ? <button onClick={() => {setLoggedIn(false); setUser({}); setPage('main');}}>Log Off</button>
          : <div>
              {newAccount
                ? <button onClick={() => setNewAccount(false)}>Log In</button>
                : <button onClick={() => setNewAccount(true)}>Sign Up</button>
              }
            </div>
        }
      </div>
    </div>
  )
}

export default AccountHeader;
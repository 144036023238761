import React, {useContext, useEffect} from 'react';

import AdminHome from './admin/AdminIndex';
import ClientHome from './client/ClientIndex';

import {APIContext} from '../../context/APIContext';
import {UserContext} from '../../context/UserContext';
import {AdminContext} from '../../context/AdminContext';

var Home = () => {

  const {getUsers} = useContext(APIContext);
  const {admin, clientList} = useContext(AdminContext);

  return (
    <div id='accountHome'>
      {admin
        ? <AdminHome />
        : <ClientHome />
      }

    </div>
  )
}

export default Home;
import React, {useContext} from 'react';

import {APIContext} from '../../../context/APIContext';
import {AdminContext} from '../../../context/AdminContext';

var ClientEntry = (props) => {

  const {getUserById} = useContext(APIContext);
  const {showClientPage, closeClientPage} = useContext(AdminContext);


  //create an on-click method to pull up user-specific data (maybe on name click?)
  return (
    <div id='clientEntry'>
      <h4 id='clientEntryName' /*onClick={(e) => {getUserById(props.id); showClientPage(e)}}*/>
        {props.firstName} {props.lastName}
      </h4>
      <h4>
        {props.sessionCount}
      </h4>
      <h4>
        Insert date here
      </h4>
      <h4>
        Insert date here
      </h4>
      <button id='viewClientButton' onClick={(e) => {getUserById(props.id); showClientPage(e)}}>View</button>
      {/* <div id='clientEntryButtons'>
        <button>Update Sessions</button>
        <button>Edit Account</button>
      </div> */}
    </div>
  )
}

export default ClientEntry;
import React, {useContext} from 'react';

import {APIContext} from '../../../context/APIContext';
import {UserContext} from '../../../context/UserContext';

var ClientHome = () => {

  const {user} = useContext(UserContext);

  return (
    <div>
      <h1>Welcome, {user.firstName} {user.lastName}</h1>
      <div>{user.sessionCount}</div>
    </div>
  )
}

export default ClientHome;
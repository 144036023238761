import React, {useState, useContext} from 'react';

import {APIContext} from '../../context/APIContext';


var Contact = () => {

  const {sendMessage} = useContext(APIContext);

  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  var messageForm = {
    firstName: fName,
    lastName: lName,
    email: email,
    message: message
  };

  return (
    <div id='contact'>
      <h1>Contact Me</h1>
      <form id='contactContent' onSubmit={(e) => {e.preventDefault(); sendMessage(messageForm)}}>
        <label>First name:<br/>
          <input type='text' id='fname' onChange={(e) => setFName(e.target.value)}/><br/>
        </label><br/>
        <label>Last name:<br/>
          <input type='text' id='lname'v onChange={(e) => setLName(e.target.value)}/><br/>
        </label><br/>
        <label>Email:<br/>
          <input type='text' id='email' onChange={(e) => setEmail(e.target.value)}/><br/>
        </label><br/>
        <label>Message: <br/>
          <textarea id='message' onChange={(e) => setMessage(e.target.value)}/><br/>
        </label><br/>
        <input id='messageSubmit' type='submit' value='Submit'/>
      </form>
    </div>
  )
}

export default Contact;
import React, {useState, useContext} from 'react';
import {Link as ScrollLink, animateScroll as scroll} from 'react-scroll';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

// import {UserContext} from '../../context/UserContext';
// import {AdminContext} from '../context/AdminContext';
import {PageContext} from '../../context/PageContext';

import MainHeader from './MainHeader.jsx';
import AccountHeader from './AccountHeader.jsx';

var Header = () => {

  // const {loggedIn, setLoggedIn, setUser} = useContext(UserContext);
  // const {page, setPage, newAccount, setNewAccount, accountMenu, setAccountMenu, showAccountMenu, closeAccountMenu} = useContext(PageContext);
  const {page} = useContext(PageContext);

  var renderHeader = (page) => {
    if (page === 'main') {
      return (<MainHeader/>);
    } else if (page === 'account') {
      return (<AccountHeader/>);
    }
  }

  return (
    <div>
      {/* <div>
        {page === 'main'
          ? <MainHeader/>
          : null
        }
        {page === 'account'
          ? <AccountHeader/>
          : null
        }
      </div> */}
      {renderHeader(page)}
    </div>
  )
}

export default Header;
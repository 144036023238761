import React, {useContext, useState} from 'react';

import {AdminContext} from '../../../context/AdminContext';

import ClientEntry from './ClientEntry';

var ClientList = (props) => {

  const {clientList} = useContext(AdminContext);

  return (
    <div id='clientList'>
      <h2>Client List</h2>
      <div id='clientTable'>
        <div id='clientListHeader'>
          <h3>Name</h3>
          <h3>Sessions</h3>
          <h3>Last Session</h3>
          <h3>Next Session</h3>
        </div>
        <hr/>
        {/*FOR MONGO*/}
        {/* {clientList.map(client =>
          <ClientEntry id={client._id} firstName={client.firstName} lastName={client.lastName} sessionCount={client.sessionCount}/>
        )} */}
        {/*FOR POSTGRES*/}
        {clientList.map(client =>
          <ClientEntry id={client.id} firstName={client.firstName} lastName={client.lastName} sessionCount={client.sessionCount}/>
        )}
      </div>
    </div>
  )
}

export default ClientList;
import React from 'react';

var Shop = () => {
  return (
    <div id='shop'>
      <h1>Shop</h1>
      <div id='shopBody'>
        <h2>Ready to look at my session/workout gear packages?</h2>
        <h3>Shopify link</h3>
      </div>
    </div>
  )
}

export default Shop;
import React from 'react';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import img1 from '../../../dist/photos/landing1.jpg';
// import img2 from '../../../dist/photos/landing2.jpg';
// import img3 from '../../../dist/photos/landing3.jpg';

var Announcements = () => {
  return (
    <div id='announcements'>
      <div id='announcementsContent'>
        <h1>Announcements</h1>
        <Carousel autoPlay={true} interval={5000} emulateTouch={true} showArrows={false} showStatus={false} swipeable={true} useKeyboardArrows={true} infiniteLoop={true} showThumbs={false}>
          <div id='tile1'>
            <h2>Program Specials</h2>
            {/* <img src={img1}></img> */}
          </div>
          <div id='tile2'>
            <h2>Client Spotlight</h2>
            {/* <img src={img2}></img> */}
          </div>
          <div id='tile3'>
            <h2>Other stuff</h2>
            {/* <img src={img3}></img> */}
          </div>
        </Carousel>
      </div>
    </div>
  )
}

export default Announcements;
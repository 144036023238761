import React, {useContext} from 'react';

import closeButton from '../../../../dist/close-button.svg'

import {UserContext} from '../../../context/UserContext';
import {AdminContext} from '../../../context/AdminContext';

var ClientInfo = () => {

  const {currentClient} = useContext(AdminContext);

  return (
    <div id='clientInfoWindow'>
      <div id='closeButtonContainer'>
        <img id='closeButton' src={closeButton}/>
      </div>
      <div id='clientInfo'>
        <h1>
          {currentClient.firstName} {currentClient.lastName}
        </h1>
        <h2>
          {currentClient.email}
        </h2>
        <h2>
          Session Count: {currentClient.sessionCount}
          {/*Include button(s) to edit session count*/}
        </h2>
        <h2>
          Last Session:
        </h2>
        <h2>
          Next Session:
        </h2>
        <h2>
          Program:
        {/*Include link to program on Google Sheets
        Maybe I'll create my own platform for programs*/}
        </h2>
      </div>
    </div>
  )
}

export default ClientInfo;
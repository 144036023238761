import React, {createContext, useContext, useState} from 'react';
import axios from 'axios';
import 'regenerator-runtime/runtime';

import {UserContext} from './UserContext';
import {AdminContext} from './AdminContext';
import {PageContext} from './PageContext';


export const APIContext = createContext({});

const APIProvider = ({children}) => {

  /********************************* Context Imports *********************************/
  const {setUser, user, setLoggedIn} = useContext(UserContext);
  const {admin, setAdmin, setClientList, setCurrentClient} = useContext(AdminContext);
  const {setNewAccount, setPage} = useContext(PageContext);

  /***********************************************************************************/
  /************************************ FIRESTORE ************************************/
  /***********************************************************************************/


  /***********************************************************************************/
  /********************************** EXPRESS SERVER *********************************/
  /***********************************************************************************/
  /**************************** Account Functions (Admin) ****************************/
  const getUsers = async(admin) => {
    if (admin) {
      try {
        const allUserInfo = await axios.get('/api/users/clients');
        setClientList(allUserInfo.data);
      }
      catch (err) {
        console.log(err);
      }
    } else {
      setClientList([]);
    }
  }

  const getUserById = async(id) => {
    try {
      const userInfo = await axios.get('/api/users/id', {params: {id: id}});
      console.log(userInfo.data);
      setCurrentClient(userInfo.data[0]);
    }
    catch (err) {
      console.log(err);
    }
  }

  /**************************** Signup/Login Functions ****************************/
  const userSignup = async(firstName, lastName, email, password) => {
    try {
      const newUserInfo = await axios.post('/api/users', {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password
      })
      if (newUserInfo.data === 'Email exists') {
        alert('Email is already in use. Please use a different email or contact your trainer to reset your login credentials.');
      } else {
        setNewAccount(false);
        alert('Account creation successful. Please log in to access your account.')
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  const userLogin = async (email, password) => {
    try {
      const userInfo = await axios.get('/api/users/login', {params: {email: email, password: password}})
      if (userInfo.data.length === 0) {
        alert('Invalid login credentials. Please recheck your email and password.');
      } else {
        if (userInfo.data[0].admin) {
          setAdmin(true);
          try {
            const allUserInfo = await axios.get('/api/users/clients')
            setClientList(allUserInfo.data);
          }
          catch (err) {
            console.log(err);
          }
        } else {
          setAdmin(false);
          setClientList([]);
        }
        setUser(userInfo.data[0]);
        setLoggedIn(true);
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  /**********************************Contact Form********************************/
  const sendMessage = async (data) => {
    axios.post('/contact', {params: {messageForm: data}})
  }

  return (
    <APIContext.Provider
      value={{
        getUsers,
        getUserById,
        userSignup,
        userLogin,
        sendMessage
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

export default APIProvider;
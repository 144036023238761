import React, {useContext, useState} from 'react';

import AdminHome from './admin/AdminIndex';
import ClientHome from './client/ClientIndex';

import {APIContext} from '../../context/APIContext';
import {UserContext} from '../../context/UserContext';
import {AdminContext} from '../../context/AdminContext';
import {PageContext} from '../../context/PageContext';

var Signup = () => {

  const {userSignup} = useContext(APIContext);
  const {setNewAccount, setPage} = useContext(PageContext);

  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  //check email function
    //use regex to check if email is valid
    var validEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(email).toLowerCase())) {
        return true;
      } else {
        alert ('Please enter a valid email address');
        return false;
      }
    }

  //check passwords function
  var checkPassword = (pass1, pass2) => {
    console.log(pass1);
    if (pass1.length < 8) {
      alert('Please enter a password of at least 8 characters')
      return false;
    } else if (pass1 !== pass2) {
      alert('Please make sure that your passwords match');
      return false;
    } else {
      return true;
    }
  }

  //check name fields
  var checkName = (firstName, lastName) => {
    if (firstName.length > 0 && lastName.length > 0) {
      return true
    } else {
      if (firstName.length === 0) {
        alert ('Please enter your first name');
      }
      if (lastName.length === 0) {
        alert ('Please enter your last name');
      }
      return false;
    }
  }

  //create account function
  var createAccount = (firstName, lastName, email, pass1, pass2) => {
    if (checkName(firstName, lastName) && validEmail(email) && checkPassword(pass1, pass2)) {
      console.log('will be created');
      userSignup(firstName, lastName, email, pass1);
    } else {
      console.log('will not be created');
    }
  }

  return (
    <div id='signup'>
      <form id='signupContent' onSubmit={(e) => {e.preventDefault(); userSignup(fName, lName, email, password1)}}>
        <h2>Sign Up</h2>
        <p onClick={() => setNewAccount(false)}>Already have an account? Log in here</p><br/>
        <label>First name:<br/>
          <input type='text' id='fname' onChange={(e) => setFName(e.target.value)}/><br/>
        </label><br/>
        <label>Last name:<br/>
          <input type='text' id='lname'v onChange={(e) => setLName(e.target.value)}/><br/>
        </label><br/>
        <label>Email:<br/>
          <input type='text' id='signupEmail' onChange={(e) => setEmail(e.target.value)}/><br/>
        </label><br/>
        <label>Password (must be at least 8 characters):<br/>
          <input type='password' id='signupPassword' onChange={(e) => setPassword1(e.target.value)}/><br/>
        </label><br/>
        <label>Confirm your password:<br/>
          <input type='password' id='passwordConfirm' onChange={(e) => setPassword2(e.target.value)}/><br/>
        </label><br/>
        <input id='accountSubmit' type='submit' value='Submit'/>
      </form>
    </div>
  )
}

export default Signup;